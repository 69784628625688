import React, { useEffect, useState, useRef } from 'react';
import { Outlet, Link } from "react-router-dom";


import axios from 'axios';
import ReactPlayer from 'react-player';


import Loading from '../utils/loading';

// Import style
import "../assets/style/pages/home.scss"

// Assets
import arrowTopRight from "../assets/images/icons/arrow-top-right.svg";
import arrowTopRightBlack from "../assets/images/icons/arrow-top-right-black.svg";
import starIcon from '../assets/images/icons/star.svg';
import el_1 from '../assets/images/icons/element_1.svg';
import el_2 from '../assets/images/icons/element_2.svg';
import el_3 from '../assets/images/icons/element_3.svg';


import ProjectItem from '../components/ProjectItem';
import BlogItem from '../components/BlogItem';
import SliderItem from '../components/SliderItem';
import ProductItem from '../components/ProductItem';
// import CustomCursor from '../style/components/CustomCursor';

import { API_URL } from "../utils/api";

// Endpoints
const endpoint = "/wp/v2/pages/53";
const endpointProjects = "/wp/v2/projects";
const endpointProducts = "/wp/v2/products";
const endpointBlog = "/wp/v2/posts";


function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [eTagValue, setETagValue] = useState(null);

  // const [content, setContent] = useState('');
  const [home, setData] = useState({});
  const [aboutImage, setUrl] = useState({});

  const [elements, sliderElements] = useState([]);


  const [posts, setBlogPosts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [products, setProducts] = useState([]);


  useEffect(() => {
    const getPageContent = async () => {
      const response = await axios.get(`${API_URL}${endpoint}`);
      setData(response.data.acf);

      var getImageUrl = await axios.get(`${API_URL}${endpoint}/?_fields=acf&acf_format=standard`);
      setUrl(getImageUrl.data.acf.about_us.section_image);

      var what_we_do = response.data.acf.what_we_do;
      sliderElements(what_we_do);
      var slidesCount = elements.length;
    };

    const getPosts = async () => {
      const response = await axios.get(`${API_URL}${endpointBlog}?_embed`);
      setBlogPosts(response.data);
    };

    const getProjects = async () => {
      const response = await axios.get(`${API_URL}${endpointProjects}?_embed`);
      setProjects(response.data);
    };

    const getProducts = async () => {
      const response = await axios.get(`${API_URL}${endpointProducts}?_embed`);
      setProducts(response.data);
    };

    getPageContent();
    getProducts();
    getProjects();
    getPosts();
    setTimeout(() => {
      setIsLoading(false);
    }, 300);

  }, []);



  return (
    <div>
      <Loading className={`${isLoading ? '' : 'fade-out'}`} />
      <div className='container'>
        <section className='hero'>
          <h1><img src={el_1} alt="Redefining and" />Redefining & &nbsp;</h1>
          <div className='d-flex'>
            <div className='col-1-2 m-none'></div><h1>&nbsp; developing &nbsp; <img src={el_2} alt="Redefining and" /></h1>
            <p className='col-2 hero-desc m-none'>{home?.hero?.hero_description}</p>
          </div>

          <div className='d-flex'>
            <div className='col-1 m-none'></div><h1><img src={el_3} alt="Redefining and" /> ideas into reality &nbsp;</h1>
          </div>

          <p className='hero-desc m-show ml-0'>{home?.hero?.hero_description}</p>

        </section>


        <section className='about mt-7'>
          <div className='d-flex justify-between col-4 m-column'>
            <p className='label'>About us</p>
            <p className='label col-2'> {home?.about_us?.subtitle} </p>
          </div>

          <div className='col-4 mt-3'>
            <p className='h-5'>
              {home?.about_us?.section_content}
            </p>
          </div>

          <div className='w-100 mt-7 about-image'>
            {/* <img src={aboutImage} alt='inity.agency' /> */}

            <ReactPlayer
              url='/video/Inity_720p.mp4'
              controls={false}
              loop={true}
              muted={true}
              width='100%'
              height='100%'
              playing={true}
              loading="lazy"
            // onVisible={true}
            />

          </div>
        </section>
        {/* 

          <section className='what-we-do mt-5'>
            <div className='d-flex alg-bottom justify-between mb-5 m-column'>
              <div>
                <span className='label'>services</span>
                <h2 className='mb-1'>What we do?</h2>
              </div>
              <a href="/blog" className='btn white icon-right'>Check our content hub <img src={arrowTopRight} alt="Check our content hub" /></a>
            </div>

            <div className='what-we-do-content slider'  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}>
              <Slider {...settings}>
                {elements.length > 0 && elements.map((element, index) => (                  
                  <SliderItem key={index} data={element} />
                ))}
              </Slider>

              {isHovered && <div className="custom-cursor" ref={cursorRef} />}
              <div className="slider-progress">
               <div className="slider-progress-bar" style={{ width: `${((sliderIndex + 1) / slidesCount) * 100}%` }} />
              </div>
            </div>
          </section> */}

        <section className='projects mt-7'>
          <div className='section-hero d-flex alg-bottom justify-between mb-5 m-column'>
            <div>
              <h2 className='mb-1 col-4'>Here's a few of our recent projects</h2>
              <span className='label'>Here's a few of our recent projecs</span>
            </div>
            <a href="/projects" className='btn white icon-right'>See more work <img src={arrowTopRight} alt="Check our content hub" /></a>
          </div>
          <div className='d-flex gap-2 project-holder'>
            {projects.slice(0, 3).map((project) => (
              <ProjectItem key={project.id} project={project} />
            ))}
          </div>
        </section>


        <section className='products mt-7'>
          <div className='section-hero d-flex alg-bottom justify-between mb-5 m-column'>
            <div className='product-hero-title d-flex alg-top m-column'>
              <img src={starIcon} alt="star_icon" className='scale-image' width="auto" height="auto"/>
              <h2 className='mb-0 col-4'>Products we can create for you</h2>
            </div>
            <a href="/contact" className='btn black icon-right'>Let’s Create <img src={arrowTopRightBlack} alt="Let’s Create" /></a>

          </div>


          <div className='product-content d-flex alg-top gap-2 m-column'>
            {products.map((product) => (
              <ProductItem key={product.id} product={product} />
            ))}
          </div>
        </section>



        <section className='blog mt-7'>
          <div className='section-hero d-flex alg-bottom justify-between mb-5 m-column'>
            <div>
              <h2 className='mb-1'>Latest blog posts</h2>
              <span className='label'>Here's a few of our recent posts</span>
            </div>
            <a href="/blog" className='btn white icon-right'>Check our content hub <img src={arrowTopRight} alt="Check our content hub" /></a>
          </div>
          <div className='blog-holder d-flex gap-2 m-column'>
            {posts.map((post) => (
              <BlogItem key={post.id} post={post} />
            ))}
          </div>

        </section>
      </div>
    </div>



  );
}

export default Home;
