import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';


import Lenis from "@studio-freight/lenis";
import logo from '../../assets/images/inity_logo.svg';
import closeIcon from '../../assets/images/icons/close.svg';

// Import style
import "../../assets/style/components/menu.scss";




import { API_URL } from "../../utils/api";

const endpoint = "/acf/v3/options/theme_settings";

const Menu = () => {

  const [contactInfo, setContactInfo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);


  const currentYear = new Date().getFullYear();

  const lenis = new Lenis({
    duration: 1.2,
    wheelMultiplier: 0.7,
    infinite: false,
    direction: "vertical",
    normalizeWheel: false,
    smoothTouch: false,
    smooth: true
  })


  function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }

  requestAnimationFrame(raf)

  const handleMenuClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (e) => {
    setIsOpen(false);
  };

  useEffect(() => {
    fetch(`${API_URL}${endpoint}`)
      .then(response => response.json())
      .then(data => {
        setContactInfo(data);
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    if (isOpen) {
      lenis.destroy();
      lenis.stop();
    } else {
      lenis.start();
    }
  }, [isOpen]);


  return (
    <>

      <div className={`nav ${isOpen ? 'opened' : 'closed'}`} id="nav">
        <div className="container">
          <div className="nav-top d-flex alg-center justify-between w-100">
            <Link to="/" onClick={handleMenuItemClick} ><img src={logo} alt="inity agency" /></Link>

            <a href=".#" className="burger d-flex alg-center" onClick={handleMenuClick}> {isOpen ? ('Close') : ('Menu ')} { isOpen ? <img src={closeIcon} width="32px" height="32px"/> : <span className="circle"><span></span></span> }</a>
          </div>
        </div>
      </div>


      <div className={`menu container  ${isOpen ? 'opened' : 'closed'}`}>
        <div className="menu-inner d-flex alg-bottom justify-between w-100">
          <div className="info">
            <h2>Inity Agency</h2>
            <div>
              <span className="label">Hey, drop us a line</span>
              {contactInfo && contactInfo.primary_email && (
                <a href={contactInfo.primary_email} className="h-5 underlined-link link-padding" data-underlined-link>{contactInfo.primary_email}</a>
              )}
            </div>


            <div className="social-media">
              <span className="label mb-1">Stay in the loop</span>
              <div className="d-flex alg-center flex-wrap social-media-links">
                {contactInfo && contactInfo.social_media.linkedin && (
                  <a href={contactInfo.social_media.linkedin} target="_blank" className="underlined-link link-padding" data-underlined-link>Linkedin</a>
                )}
                {contactInfo && contactInfo.social_media.instagram && (
                  <a href={contactInfo.social_media.instagram} target="_blank" className="underlined-link link-padding" data-underlined-link>Instagram</a>
                )}
                {contactInfo && contactInfo.social_media.dribbble && (
                  <a href={contactInfo.social_media.dribbble} target="_blank" className="underlined-link link-padding" data-underlined-link>Dribbble</a>
                )}
                {contactInfo && contactInfo.social_media.behance && (
                  <a href={contactInfo.social_media.behance} target="_blank" className="underlined-link link-padding" data-underlined-link>Behance</a>
                )}
              </div>
            </div>
          </div>
          <ul>
            <li>
              <Link to="/" onClick={handleMenuItemClick} >Home</Link>
            </li>
            <li>
              <Link to="/projects" onClick={handleMenuItemClick} >Projects</Link>
            </li>
            <li>
              <Link to="/blog" onClick={handleMenuItemClick} className="menu-sub">Chronicle <span className="label">blog</span></Link>
            </li>
            <li>
              <Link to="/contact" onClick={handleMenuItemClick} >Contact</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={`space ${isOpen ? 'show' : 'hide'}`}></div>
    </>
  )
};

export default Menu;