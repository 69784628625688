// import logo from './logo.svg';
// import './App.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Loading from "../utils/loading";
import ProjectItem from '../components/ProjectItem';

// Import style
import "../assets/style/pages/projects.scss";


import { API_URL } from '../utils/api';
const endpoint = "/wp/v2/projects";


function Projects() {
    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState([]);

    useEffect(() => {

        document.body.classList.add('projects-template');


        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${API_URL}${endpoint}?_embed`);
                setProjects(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchProjects();
        setTimeout(() => {
            setIsLoading(false);
          },300);
        return () => {
            document.body.classList.remove('projects-template');
        };

    }, []);


    return (

        <div className='mt-5'>



            <Loading className={`${isLoading ? '' : 'fade-out'}`} />
            <div className="project-wrapper container">
                <span className='label'>Our work</span>
                <h1 className='mb-5 col-4'>A list of success stories we're proud of</h1>
                <div className="project-holder d-flex gap-2 alg-top">

                    {projects.map(project => (
                        <ProjectItem key={project.id} project={project} />
                    ))}
                </div>
            </div>
        </div>


    );
}
export default Projects;
