import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter , Routes, Route} from "react-router-dom";

// Import Pages
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import SingleBlog from "./pages/SingleBlog";
import Projects from "./pages/Projects";
import SingleProject from "./pages/SingleProject";
import Contact from "./pages/Contact";
import PrivacyPolicy from './pages/PrivacyPolicy';
import NoPage from "./pages/NoPage";

// Import Styles
import './assets/style/global.scss';

export default function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="blog" element={<Blog />} />
            <Route path="/blog/:id" element={<SingleBlog />} />
            <Route path="projects" element={<Projects />} />
            <Route path="/projects/:id" element={<SingleProject />} />
            <Route path="contact" element={<Contact />} />
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
