import React from 'react';
import '../assets/style/loading.scss';


const Loading = ({ className }) => {
  return (
    <div className={`loading ${className}`}>
      <div className="loading-text">
        <span className="loading-text-words">I</span>
        <span className="loading-text-words">N</span>
        <span className="loading-text-words">I</span>
        <span className="loading-text-words">T</span>
        <span className="loading-text-words">Y</span>
      </div>
    </div>
  );
};

export default Loading;
