// import logo from './logo.svg';
// import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Loading from "../utils/loading";
import BlogItem from '../components/BlogItem';

// Import style
import "../assets/style/pages/blog.scss";


import { API_URL } from '../utils/api';
const endpoint = "/wp/v2/posts";

function Blog() {
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        document.body.classList.add('blog-template');

        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${API_URL}${endpoint}?_embed`);
                setPosts(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchPosts();
        setTimeout(() => {
            setIsLoading(false);
          },300);
        return () => {
            document.body.classList.remove('blog-template');
        };

    }, []);



    return (
        <div className='mt-5'>


            <Loading className={`${isLoading ? '' : 'fade-out'}`} />

            <div className="blog-wrapper container">
                <span className='label'>Our content hub</span>
                <h1 className='mb-5'>Behind the scenes</h1>
                <div className='blog-holder d-flex alg-top'>

                    {posts.map(post => (
                        <BlogItem key={post.id} post={post} />
                    ))}
                </div>
            </div>
        </div>

    );
}

export default Blog;
