import React from 'react';
import { Link } from 'react-router-dom';

const ProjectItem = ({ project }) => {
  return (
        <Link 
        to={`/projects/${project.id}`}  
        className="project-item" 
        key={project.id} 
        style={{'--element-color': project.acf.project_color}}
        >
        <div className='project-item-img'>
        <img
            src={
            project._embedded['wp:featuredmedia'][0].media_details.sizes.full
                ? project._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url
                : project._embedded['wp:featuredmedia'][0].source_url
            }
            alt={project.title.rendered}
            loading="lazy"
        />
        </div>
        
        <div className='d-flex alg-top flex-column project-item-content flex-column'>
          <h3 className="h-5 underlined-link link-padding" data-underlined-link>{project.title.rendered}</h3>
          <div dangerouslySetInnerHTML={{ __html: project.excerpt.rendered }} className='project-item-excerpt'/>
        </div>
        </Link>
  );
};

export default ProjectItem;
