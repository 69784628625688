import React from 'react';
import { Link } from 'react-router-dom';

const ProductItem = ({ product }) => {
  return (
    <div className="product-item" key={product.id}>
        <img
            src={
            product._embedded['wp:featuredmedia'][0].media_details.sizes.medium
                ? product._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
                : product._embedded['wp:featuredmedia'][0].source_url
            }
            alt={product.title.rendered}
        />
        
        <h3>{product.title.rendered}</h3>
        <p dangerouslySetInnerHTML={{ __html: product.excerpt.rendered }} />
    </div>
  );
};

export default ProductItem;
