import React from 'react';
import { Link } from 'react-router-dom';

const BlogItem = ({ post }) => {
  return (
    
    <Link to={`/blog/${post.id}`} className='blog-item' key={post.id}>

      <div className='blog-item-img'>
        {post.featured_media && (
          <img
            src={post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url}
            alt={post.title.rendered} width="410" height="300"
            loading="lazy"
          />
        )}
      </div>

      <div className="blog-item-content">
        <span className='label cats'>
          {' '}
          {post._embedded['wp:term'] &&
            post._embedded['wp:term'][0].map(category => category.name).join(' · ')}
        </span>
        <h3 className='h-5 underlined-link' data-underlined-link>{post.title.rendered}</h3>
        <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />

      </div>
    </Link>
  );
};

export default BlogItem;
