import { Outlet, Link, useLocation} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Lenis from '@studio-freight/lenis'


import Menu from "../components/common/Menu";
import Footer from "../components/common/Footer";

import { hover } from '../utils/hover';
// import { backToTop } from "../utils/backToTop";

const Layout = () => {

  const location = useLocation();

  useEffect(() => {
    hover();
  }, [location.pathname]);
  return (
    <>
      <Menu />

      <Outlet />

      <Footer />
    </>
  )
};

export default Layout;