import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import axios from 'axios';
import { useParams } from "react-router-dom";
import moment from 'moment';

import Loading from "../utils/loading";


import arrowTopLeft from '../assets/images/icons/arrow-top-left.svg';

import { API_URL } from '../utils/api';
const endpoint = "/wp/v2/posts/";

function SingleBlog() {
  const [isLoading, setIsLoading] = useState(true);

  const [post, setPost] = useState(null);
  const [hero, setHero] = useState(null);
  const [error, setError] = useState(null);

  const { id } = useParams();
  var formattedDate;


  useEffect(() => {

    document.body.classList.add('single-blog-template');

    fetch(`${API_URL}${endpoint}${id}?_embed`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong while fetching the post');
      })
      .then(data => {
        setPost(data);
        axios.get(`${API_URL}${endpoint}${id}/?_fields=acf&acf_format=standard`)
          .then(response => {
            setHero(response.data.acf.hero_image);

            setTimeout(() => {
              setIsLoading(false);
            }, 300);
          }).catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        setError(error.message);
      })

    return () => {
      document.body.classList.remove('single-blog-template');
    };

  }, [id]);

  // if (!post) {
  //   return null;
  // }

  if (post && post.date) {
    formattedDate = moment(post.date).format('MMM DD, YYYY');
  }

  return (

    <>
      <Loading className={`${isLoading ? '' : 'fade-out'}`} />
      <div className='container mt-5'>


        <Link to='/blog' className="underlined-link link-icon" data-underlined-link><img src={arrowTopLeft} alt="Back to top" className="icon" /> Back to all blogs</Link>

        {post && (
          <>
            <h1 className='mb-5 mt-2'>{post.title.rendered}</h1>
            <div className='hero-image img-holder' >
              <img src={hero} alt={post.title.rendered} />
            </div>
            <div className='d-flex mt-5 blog-content'>
              <div className='col-2 details'>
                <p className='mb-0'>{post.acf.author.name}</p>
                <span className='label mb-1'>{post.acf.author.position}</span>
                <hr />

                <div className='d-flex alg-center flex-wrap reading'>
                  <p className='mb-0'>{post.acf.time_for_reading} MIN <span>&nbsp; read in</span></p>
                  {post._embedded && post._embedded['wp:term'] && post._embedded['wp:term'][0].map(category => (
                    <span key={category.id} className='btn white'>
                      {category.name}
                    </span>
                  ))}


                </div>
                <div> <span className='uppercase'>{post.status}</span> <span>·</span> <span className=''>{formattedDate}</span></div>

              </div>

              <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} className='col-4 blog-content-text' />

            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SingleBlog;
