import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Loading from "../utils/loading";

import { API_URL } from '../utils/api';
const endpoint = "/wp/v2/pages/3";

function PrivacyPolicy() {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    const getPageContent = async () => {
      const response = await axios.get(`${API_URL}${endpoint}`);
      setContent(response.data.content.rendered);
      setTitle(response.data.title.rendered);
      setIsLoading(false);
    };

    getPageContent();
  }, []);

  return (
    <div className='mt-5'>

      <Loading className={`${isLoading ? '' : 'fade-out'}`} />

      <div className='container'>
        <div className='col-4'>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
