import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";

import axios from 'axios';
import { useParams } from "react-router-dom";

import Loading from '../utils/loading';

import arrowTopLeft from '../assets/images/icons/arrow-top-left.svg';

import { API_URL } from '../utils/api';
const endpoint = "/wp/v2/projects/";


var year;

function SingleProject() {

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);
  const { id } = useParams();

  const [services, setServices] = useState([]);

  const [heroImage, setHeroImage] = useState(null);
  const [secondImage, setSecondImage] = useState(null);
  const [bottomImage1, setBottomImage1] = useState(null);
  const [bottomImage2, setBottomImage2] = useState(null);

  const [metric, setMetrics] = useState([]);

  const [challenge, setChallenge] = useState([]);
  const [results, setResults] = useState([]);



  useEffect(() => {

    document.body.classList.add('single-project-template');

    const fetchProject = async () => {
      try {
        const response = await axios.get(`${API_URL}${endpoint}${id}?_embed`);
        setProject(response.data);

        var servicesList = response.data.acf.services;
        setServices(servicesList);

        var getImageUrl = await axios.get(`${API_URL}${endpoint}${id}/?_fields=acf&acf_format=standard`);
        setHeroImage(getImageUrl.data.acf.project_hero_image.url);
        setSecondImage(getImageUrl.data.acf.project_image_2.url);
        setBottomImage1(getImageUrl.data.acf.project_image_3.url);
        setBottomImage2(getImageUrl.data.acf.project_image_4.url);

        var metricList = response.data.acf.overview.metrics;
        setMetrics(metricList);

        var challengeList = response.data.acf.challenge;
        setChallenge(challengeList);

        var resultList = response.data.acf.results;
        setResults(resultList);

      } catch (error) {
        console.log(error);
      }
    };

    fetchProject();
    setTimeout(() => {
      setIsLoading(false);
    }, 300);

    return () => {
      document.body.classList.remove('single-project-template');
    };
  }, [id]);


  if (project && project.acf.year) {
    const date = project.acf.year;
    year = date.substring(0, 4);
  }


  return (
    <>
      <Loading className={`${isLoading ? '' : 'fade-out'}`} />
      <div className='container mt-5'>
        <Link to='/projects' className="underlined-link link-icon" data-underlined-link><img src={arrowTopLeft} alt="Back to top" className="icon" /> Back to all projects</Link>

        {project && (
          <>
            <section className='project-hero d-flex alg-top justify-between m-column mb-5 mt-2'>
              <div className='col-4'>
                <h1 className='project-title'>{project.title && project.title.rendered}</h1>
                <div className='d-flex alg-top'>
                  <p className='mb-0 mr-1'>//</p>
                  <p dangerouslySetInnerHTML={{ __html: project.excerpt && project.excerpt.rendered }} className='col-3'></p>
                </div>
              </div>

              <div className='col-2'>
                <div className='categories d-flex flex-wrap gap-05 mb-2'>
                  {services.length > 0 && services.map((item, index) => (
                    <a className='btn white'>{item.services_title}</a>
                  ))}
                </div>

                <div className='d-flex alg-top'>
                  <div className='col-3'>
                    <span className='label'>YEAR</span>
                    <p>{year}</p>
                  </div>

                  <div className='col-3'>
                    <span className='label'>CLIENT</span>
                    <p>{project?.acf?.client}</p>
                  </div>
                </div>
              </div>
            </section>


            <div className='hero-image img-holder' >
              <img src={heroImage} alt={project.title.rendered} />
            </div>


            <section className='overview d-flex alg-top mt-5 m-column'>
              <div className='col-2'></div>
              <div className='col-4 d-flex m-column'>
                <h3 className='col-2'>Overview</h3>


                <div className='col-4'>
                  <div dangerouslySetInnerHTML={{ __html: project.acf.overview.overview_description }} />
                  <div className='metrics-items m-column'>
                    {metric.map((metric, index) => (
                      <div key={index} className='metrics-items-item'>
                        <h5>{metric.itmem_number}</h5>
                        <p>{metric.desc}</p>
                      </div>
                    ))}

                  </div>
                </div>
              </div>
            </section>

            <section className='challenge d-flex alg-top mt-5 m-column'>
              <div className='col-2'></div>
              <div className='col-4 d-flex m-column'>
                <h3 className='col-2'>Challenge</h3>

                <div className='col-4'>
                  <div className='challenge-item'>
                    {challenge.length > 0 && challenge.map((element, index) => (
                      <p className='item-line'>{element.item}</p>
                    ))}

                  </div>
                </div>
              </div>
            </section>


            <div className='hero-image img-holder mt-5' >
              <img src={secondImage} alt={project.title} />
            </div>


            <section className='results d-flex alg-top mt-5 m-column'>
              <div className='col-4 d-flex m-column'>
                <h3 className='col-2'>Results</h3>
                <div className='col-4'>
                  <div className='results-item'>
                    {results.length > 0 && results.map((element, index) => (
                      <p className='item-line'>{element.item}</p>
                    ))}

                  </div>
                </div>
              </div>


              <div className='col-2'></div>
            </section>

            <section className='images d-flex alg-item m-column mt-5 m-column'>

              <div className='col-3'>
                <img src={bottomImage1} alt={project.title} />
              </div>
              <div className='col-3'>
                <img src={bottomImage2} alt={project.title} />
              </div>
            </section>

          </>
        )}


      </div >
    </>
  );
}

export default SingleProject;
