export function hover() {
    setTimeout(function () {

        var uLinkAttr = 'data-underlined-link';

        var uLinks = document.querySelectorAll('[' + uLinkAttr + ']');

        for (var u = 0; u < uLinks.length; u++) {
            uLinks[u].setAttribute(uLinkAttr, uLinks[u].innerText);
        }
    }.bind(this), 1000)

}