import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';


import Loading from '../utils/loading';

// Import style
import "../assets/style/pages/contact.scss"

import downloadIcon from '../assets/images/icons/download.svg';
import starIcon from '../assets/images/icons/star.svg';

import { API_URL } from "../utils/api";
const endpoint = "/acf/v3/options/theme_settings";


function Contact() {
  const [isLoading, setIsLoading] = useState(true);
  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}${endpoint}`)
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        setContactInfo(data);
        setTimeout(() => {
          setIsLoading(false);
        },300);
      })
      .catch(error => console.error(error));

  }, []);

  return (

    <div className="mt-5">

      <Loading className={`${isLoading ? '' : 'fade-out'}`} />
      <div className="container" >



        <div className="d-flex alg-top justify-between m-column">
          <div className="page-title col-4">
            <span className="label">Let’s create </span>
            <h1>Have a project in mind? <br /><i>Say hello</i></h1>
          </div>

          <div className="contact-items col-2">
            <div className="contact-item">
              <span className="label">Drop us a line</span>
              {contactInfo && contactInfo.primary_email && (
                <a href={contactInfo.primary_email} className="underlined-link link-padding" data-underlined-link>{contactInfo.primary_email}</a>
              )}
            </div>

            <div className="contact-item">
              <span className="label">Pick a time to connect</span>
              {contactInfo && contactInfo.calendly && (
                <a href={contactInfo.calendly} target="_blank" className="underlined-link link-padding" data-underlined-link>Schedule a meeting</a>
              )}
            </div>

            <div className="contact-item">
              <span className="label">Locations</span>
              {contactInfo && contactInfo.location && (
                <p dangerouslySetInnerHTML={{ __html: contactInfo.location }}></p>
              )}
            </div>

            <div className="contact-item social-media">
              <span className="label">Stay in the loop</span>
              <div className="d-flex alg-center flex-wrap social-media-links">
                {contactInfo && contactInfo.social_media.linkedin && (
                  <a href={contactInfo.social_media.linkedin} target="_blank" className="underlined-link  large" data-underlined-link>Linkedin</a>
                )}
                {contactInfo && contactInfo.social_media.instagram && (
                  <a href={contactInfo.social_media.instagram} target="_blank" className="underlined-link  large" data-underlined-link>Instagram</a>
                )}
                {contactInfo && contactInfo.social_media.dribbble && (
                  <a href={contactInfo.social_media.dribbble} target="_blank" className="underlined-link large" data-underlined-link>Dribbble</a>
                )}
                {contactInfo && contactInfo.social_media.behance && (
                  <a href={contactInfo.social_media.behance} target="_blank" className="underlined-link large" data-underlined-link>Behance</a>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="partnership">

          <div className="d-flex alg-top md-column">
            {contactInfo && contactInfo.partnership.title && (
              <div className="d-flex alg-top col-4">
                <img src={starIcon} alt="star_icon" className="mr-1 scale-image" />
                <div>
                  <h2>{contactInfo.partnership.title}</h2>
                  {/* <p>{contactInfo.partnership.description}</p> */}
                </div>
              </div>
            )}

            {contactInfo && contactInfo.capabilities_deck && (
              <a href={contactInfo.capabilities_deck} target="_blank" className="btn black col-2 d-flex justify-between">Capabilities deck <img src={downloadIcon} alt="download_icon" /></a>
            )}
          </div>

          {contactInfo && contactInfo.partnership.title && (
            <div className="d-flex w-100">
              <p className="desc">{contactInfo.partnership.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>



  );
}

export default Contact;
