import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Lenis from "@studio-freight/lenis";


import arrow from '../../assets/images/icons/arrow-top.svg';

// Import style
import '../../assets/style/components/footer.scss';



import { API_URL } from "../../utils/api";
const endpoint = "/acf/v3/options/theme_settings";

const Footer = () => {
    const [contactInfo, setContactInfo] = useState(null);

    const currentYear = new Date().getFullYear();
 

    useEffect(() => {
        fetch(`${API_URL}${endpoint}`)
            .then(response => response.json())
            .then(data => {
                setContactInfo(data);
            })
            .catch(error => console.error(error));
    }, []);


    return (
        <footer>
            <div className="container">
                <hr className="footer-line" />
                <div className="d-flex justify-between alg-top">
                    <div>
                        <span className="label">Hey, drop us a line</span>
                        {contactInfo && contactInfo.primary_email && (
                            <a href={contactInfo.primary_email}  className="h-5 underlined-link link-padding" data-underlined-link>{contactInfo.primary_email}</a>
                        )}
                    </div>

                    <div className="d-flex alg-bottom gap-2">
                        <div className="d-flex alg-top gap-2">
                            <div className="location">
                                <span className="label">Where we are located?</span>
                                {contactInfo && contactInfo.location && (
                                    <p dangerouslySetInnerHTML={{ __html: contactInfo.location }}></p>
                                )}
                            </div>

                            <div className="social-media">
                                <span className="label">Stay in the loop</span>
                                <div className="d-flex alg-center flex-wrap social-media-links">
                                    {contactInfo && contactInfo.social_media.linkedin && (
                                        <a href={contactInfo.social_media.linkedin} target="_blank" className="underlined-link link-padding" data-underlined-link>Linkedin</a>
                                    )}
                                    {contactInfo && contactInfo.social_media.instagram && (
                                        <a href={contactInfo.social_media.instagram} target="_blank" className="underlined-link link-padding" data-underlined-link>Instagram</a>
                                    )}
                                    {contactInfo && contactInfo.social_media.dribbble && (
                                        <a href={contactInfo.social_media.dribbble} target="_blank" className="underlined-link link-padding" data-underlined-link>Dribbble</a>
                                    )}
                                    {contactInfo && contactInfo.social_media.behance && (
                                        <a href={contactInfo.social_media.behance} target="_blank" className="underlined-link link-padding" data-underlined-link>Behance</a>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="d-flex justify-between mt-2 copy">
                    <span>© {currentYear} - Inity Agency. All rights reserved.</span>

                    <Link to="/privacy-policy">Privacy Policy</Link>
                </div>


            </div>
        </footer>
    );
}

export default Footer;
